html,
body,
*{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.4;
}
html, body, *, ::after, ::before{
  box-sizing: border-box;
}

a, p, li{
  color: $black;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
}

img{
  max-width: 100%;
}

.button{
  display: inline-block;
  background: $blue;
  padding: 30px 45px;
  color: $white;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  &:hover{
    opacity: .8;
  }
}

.link{
  color: $blue;
  text-decoration: underline;
}
.btm-margin-20{
  margin-bottom: 20px;
}

.flex-box{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  &__center{
    align-items: center;
  }
}

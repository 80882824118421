h1, h2, h3, h4, h5, h6{
  line-height: 1.4;
  font-weight: 900;
  color: $black;
  text-transform: uppercase;
  &.normal{
    font-weight: 400;
  }
}
h1{
  font-size: 36px;
}
h2{
  font-size: 32px;
}
h3{
  font-size: 28px;
}
h4{
  font-size: 24px;
}
h5{
  font-size: 20px;
  margin-bottom: 40px;
}
h6{
  font-size: 16px;
  margin-bottom: 20px;
  text-transform: none;
}


strong, .bold, b{
  font-weight: 900;
}

p{
  margin-bottom: 20px;
}

.uppercase{
  text-transform: uppercase;
}

.no-margin{
  margin-bottom: 0;
}

.header{
  padding: 65px 0;
  margin-bottom: 45px;
  &__top{
    margin-bottom: 60px;
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  &__info{
    padding: 20px 15px;
    font-weight: 700;
    @include sm{
      text-align: right;
    }
    &--top{
      font-size: 24px;
      margin-bottom: 45px;
      display: inline-block;
    }
    @media screen and (max-width: 767px) {
      &--top{
        margin-bottom: 0;
      }
    }
    &--bottom{
      text-transform: uppercase;
      color: rgba(0,187,219, 1);
      font-weight: 900;
    }
    .normal{
      font-weight: 400;
      text-transform: none;
      color: $black;
    }
  }
  &__home{
    width: 100%;
    min-height: 100vh;
    background: url(../img/home-top.jpg) no-repeat center center / cover;
  }
  &__page{
    height: auto;
  }
}
.header__home{
  .header{
    &__info{
      color: $white;
      &--top{
        background: rgba(0,187,219, .4);
      }
      &--bottom{
        color: $white;
        font-weight: 700;
      }
      .normal{
        color: $white;
      }
    }
  }
}
@include sm{
  .text-right{
    text-align: right;
  }
}

.logo{
  &__wrapper{
    margin-top: 20px;
    padding-left: 110px;
  }
    @media screen and (max-width: 767px) {
      &__wrapper{
        padding-left: 0;
      }
    }
  &__link{
    &--img{
      width: 390px;
      max-width: 100%;
      margin-bottom: 20px;
    }
    &--text{
      display: block;
      max-width: 480px;
      font-size: 24px;
      line-height: 30px;
      padding-left: 44px;
    }
    &--text-home{
      color: $white;
    }
  }
}

.menu{
  &__container{
    border-top: solid 1px $black;
    border-bottom: solid 1px $black;
  }
  &__wrapper{
    display: flex;
    justify-content: space-between;
  }
  &__item{
    position: relative;
    .submenu{
      display: none;
      width: 100%;
      position: absolute;
      padding: 20px;
      background: $black;
      &__item-link{
        display: inline-block;
        color: $white;
        font-size: 14px;
        line-height: 1;
        padding: 10px 0;
      }
    }
    &--active,
    &:hover{
      .menu__item-link{
        color: $white;
        background: $black;
      }
    }
    &:hover{
      .submenu{
        display: block;
      }
    }
    @media only screen and (max-width: 767px) {
      text-align: center;
      .submenu{
        display: block;
        position: relative;
      }
    }
  }
  &__item-link{
    display: inline-block;
    color: $black;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1;
    padding: 24px 20px;
  }

}
.menu--home{
  &.menu{
    &__container{
      border-top: solid 1px $white;
      border-bottom: solid 1px $white;
      @media only screen and (max-width: 767px) {
        background: $white;
      }
    }
    .menu__item-link{
      color: $white;
    }
  }

}

.sticky-menu{
  &.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    z-index: 100;
  }
}

.page,
.home{
  .slide-trigger{
    display: none;
    cursor: pointer;
  }
}

.page{
  .menu__container{
    background: $white;
  }
}

@media only screen and (max-width: 767px) {

  .page,
  .home{
    .slide-trigger{
      display: block;
      font-size: 20px;
      text-align: center;
      padding: 24px 0;
    }
    .navigation{
      display: none;
      background: $black;
      padding: 20px 0;
      .menu__item-link{
        color: $white;
        width: 100%;
        padding: 10px 0;
      }
      .submenu{
        padding: 0;
      }
    }
  }
}


.content{
  margin-bottom: 140px;
  ul{
    margin-bottom: 20px;
    li{
      padding-left: 15px;
      position: relative;
      &::before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 10px;
        width: 5px;
        height: 5px;
        background: $black;
        border-radius: 50%;

      }
    }
  }
}

.footer{
  padding: 10px 0 45px;
  &__page{
    padding-top: 45px;
    border-top: solid 1px $black;
  }
  &__top{
    margin-bottom: 35px;
    position: relative;
    padding-right: 45px;
  }
  &__social{
    display: block;
    position: absolute;
    width: 25px;
    right: 0;
    top: 5px;
  }
  &__email{
    margin-bottom: 45px;
  }
  &__email-link{
    span{
      font-weight: 900;
    }
  }
  &__bottom{
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }
  &__link{
    margin-right:40px
  }
  @media only screen and (max-width: 767px) {
    &__link{
      display: block;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

// A map of breakpoints.

  $xs: 576px;
  $sm: 768px;
  $md: 992px;
  $lg: 1200px;


// Small devices
@mixin xs {
   @media (min-width: #{xs}) {
       @content;
   }
}

// Medium devices
@mixin sm {
   @media (min-width: #{$sm}) {
       @content;
   }
}

// Large devices
@mixin md {
   @media (min-width: #{$md}) {
       @content;
   }
}

// Extra large devices
@mixin lg {
   @media (min-width: #{$lg}) {
       @content;
   }
}


// colors
$white: #fff;
$black: #000;
$blue: #00bbdb
